.table {
    border-collapse: collapse;
    width: 100%;
    background: #fafafa;
}

.table th,
.table td {
    border-bottom: 2px solid #ddd;
    padding: 8px;
    background: transparent;
}

.table th {
    white-space: nowrap;
    text-align: left;
    padding-bottom: 0;
    font-size: 16px;
    font-weight: 700;
}