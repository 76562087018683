.chat-list {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 70%, #e6e6e6 100%);
  width: 400px;
  height: calc(100vh - 87px);
  background-color: #e2e2e2;
  overflow-y: scroll;
}
.list-element {
  height: 80px;
  /* background-color: #efefef; */
  padding: 10px;
  padding-left: 15px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  margin-bottom: 10px;
}
.list-pic {
  width: 40px;
  height: 40px;
  background-color: #b3b3b3;
  border-radius: 50%;
}
.list-user-name {
  padding-top: 5px;
  margin-left: 5px;
}
.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 87px);
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-top: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.chat-header {
  width: 100%;
  max-width: 100%;
}
.chat-list-number {
  color: black;
  font-family: arial;
  font-weight: 200;
}
.message-list {
  flex: 1;
  overflow-y: auto;
  background: #fafafa;
  border-bottom: 1px solid #ddd;
  padding-top: 80px; /* Add this line to account for the fixed chat-info height */
}

.message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  /* max-width: 80%; */
  clear: both;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.message.user {
  background: #007bff;
  color: #fff;
  align-self: flex-end;
  text-align: right;
}

.message.other {
  background: #fff;
  color: #000;
  align-self: flex-start;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: #fff;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.message-input button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  background: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.message-input button:hover {
  background: #0056b3;
}

.cont {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.chat-info {
  position: fixed;
  top: 87px;
  width: 100%;
  max-width: 100%;
  height: 80px;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.chat-number-list {
  color: black;
  font-weight: 300px;
  font-family: monospace;
}

.chat-tile {
  width: 100%;
  height: 70px;
  border-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.chat-pic {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chat-inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-inline-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.del {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  background-color: rgba(0, 0, 0, 0);
}
