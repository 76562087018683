@media only screen and (max-width: 768px) {
    .sideMenu {
        display: none;
    }
}

.top-holder {
    height: 86px;
}


.top-holder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.middle-holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.middle-holder span a {
    color: white;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: 800;
    letter-spacing: 1px;
}

.aisms{
    color: white;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 25px;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: 800;
    letter-spacing: 1px;
}
.side-menu {
    background-color: #1f9ad6;
    height: 100vh;
    background-image: url(../../assets/sideback-resize.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    display: inline-flex;
    flex-direction: column;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.arrow {
    margin-left: auto;
    transition: transform 0.3s;
    font-size: 20px;
  }
  
  .arrow.up {
    transform: rotate(90deg);
  }
  