.mainperformancediv{
    display: grid ;
grid-template-columns: repeat(1, minmax(0, 1fr)); 
gap: 1.5rem; 




}

.mainperformancedivpart2{
    display: grid ;
grid-template-columns: repeat(2, minmax(0, 1fr)); 
gap: 1rem; 







}

@media (min-width: 1024px) { 
    .mainperformancediv{
        display: grid ;
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
    gap: 1.5rem; 
    }
    .mainperformancepartdiv{
        grid-column: span 2;

    }
   }

   