@media only screen and (max-width: 768px) {

    .mainSection {
        margin-left: 0px;
    }
}

.mainContainer {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}