/* src/components/TopNav.module.css */
.customTopNav {
    z-index: 2;
    position: fixed;
    top: 0;
    width: calc(100% - 250px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;
}

.profileIconNav {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.collapsedNav {
    width: 40px;
    height: 40px;
    border: none;
    background-color: white;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.collapsedNav div {
    width: 25px;
    height: 3px;
    background-color: #1f9ad6;
    margin: 4px 0;
}

.line {
    padding: 2px;
    background-color: #1f9ad6;
    margin-bottom: 5px;
}

.line:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
    .customTopNav {
        width: 100%;
    }

    .profileIconNav {
        display: none;
    }

    .collapsedNav {
        display: flex;
    }
}

.pageHeading {
    font-size: var(--xl);
    color: var(--color-text);
    font-weight: 900;
    margin: 0;
    font-size: 36px;
}

.topNavWithName {
    background-color: white;
    height: 87px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (max-width: 425px) {
    .pageHeading {
        font-size: var(--xl);
        color: var(--color-text);
        font-weight: 900;
        margin: 0;
        font-size: 26px;
    }
}